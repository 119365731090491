import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import NewsLayout from '@/components/NewsLayout'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/Service.vue')
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('@/views/News.vue')
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () => import('@/views/About.vue')
  },
  {
    path: '/news',
    component: NewsLayout,
    redirect: '/news/list',
    meta: {
      title: 'Example',
    },
    children: [
      {
        path: 'details/:id',
        component: () => import('@/views/NewsDetails'),
        meta: { title: 'Create Article' }
      },
      {
        path: 'list',
        component: () => import('@/views/NewsList'),
        name: 'ArticleList',
        meta: { title: 'Article List' }
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
