import Vue from 'vue'
import {
    Carousel,
    CarouselItem,
    Backtop,
    Icon,
    Pagination,
    Drawer,
    Menu,
    MenuItem,
    Submenu,
    Row,
    Col,
    Card,
    Tabs,
    TabPane,
} from 'element-ui'

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(TabPane)