<template>
  <div class="nav-container">
    <el-row>
      <el-col :xs="12" :sm="6" :md="5" :lg="4" class="col-father-left">
        <div class="left-brand">
          <router-link to="/" class="navbar-brand">
            <!-- SEO优化 -->
            汇盛金丰
          </router-link>
        </div>
      </el-col>
      <el-col :sm="16" :md="12" :lg="12" class="hidden-xs-only col-father-right">
        <div>
          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#f0ad4e"
            router
          >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-menu-item index="/service">产品解决方案</el-menu-item>
            <el-menu-item index="/news/list">新闻资讯</el-menu-item>
            <!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
            <el-submenu index="2">
              <template slot="title">关于我们</template>
              <el-menu-item index="/about?item=introduce">公司简介</el-menu-item>
              <el-menu-item index="/about?item=project">服务项目</el-menu-item>
              <el-menu-item index="/about?item=contact">联系我们</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-col>

      <el-col :xs="12" class="hidden-sm-and-up">
        <div class="mynav-button">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="drawer = !drawer"
          >
            <span class="navbar-toggler-icon fa fa-bars"></span>
          </button>
        </div>
      </el-col>
    </el-row>

    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      direction="ltr"
      :size="'40%'"
    >
      <div class="nav-wraper" style="background-color: #545c64">
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
        >
          <el-menu-item index="/home" @click="closeDrawer">
            <i class="el-icon-s-home"></i>
            <span>首页</span>
          </el-menu-item>
          <el-menu-item index="/service" @click="closeDrawer">
            <i class="el-icon-menu"></i>
            <span slot="title">服务</span>
          </el-menu-item>
          <el-menu-item index="/news" @click="closeDrawer">
            <i class="el-icon-document"></i>
            <span slot="title">新闻资讯</span>
          </el-menu-item>
          <el-menu-item index="/about" @click="closeDrawer">
            <i class="el-icon-setting"></i>
            <span slot="title">关于我们</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
  },
};
</script>

<style lang="scss" scoped>
.nav-container {
  // display: flex;
  // align-items: center;
   position: fixed;
    width: 100%;
    z-index: 2012;
    background-color: #fff;
  .left-brand {
    padding-top: 10px;
    padding-left: 20px;
  }
}
.navbar-brand {
  display: block;
  width: 168px;
  height: 45px;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100% 100%;
  text-indent: -999em;
}

.nav-wraper {
  width: 100%;
  height: 100%;
}

// .nav-item .router-link-active {
//   color: #585858;
// }

.w3l-bootstrap-header .navbar-light .nav-item .router-link-active {
  color: #f0ad4e;
}

// .el-menu-item.is-active {
//   background-color: rgb(67, 74, 80) !important;
// }

.mynav-button {
  height: 50px;
  line-height: 50px;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
}

.el-menu-item:hover {
  color: #f0ad4e !important;
}

.mynav-button .navbar-toggler {
  padding: 0.25rem 0.6rem;
  font-size: 1rem;
  background-color: var(--opposite-color);
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
}

// @media (min-width: 992px) {
//   .left-brand .navbar-toggler {
//     display: none;
//   }
// }

@media (max-width: 992px) {
  .mynav-button span.navbar-toggler-icon.fa.fa-bars {
    padding-top: 4px;
  }
  .mynav-button .navbar-toggler-icon {
    background-image: none !important;
    color: #fff;
  }

  .middle-menu {
    display: none;
  }
}

// @media screen and (max-width: 960px) {
// }

@media screen and (max-width: 767px) {
  // .nav-container {
  //   position: fixed;
  //   width: 100%;
  //   z-index: 2012;
  //   background-color: #fff;
  // }

  .nav-container .left-brand {
    padding-top: 5px;
  }

  .col-father-left {
    float: right;
    overflow: hidden;
  }

  .col-father-right {
    float: left;
    overflow: hidden;
  }

  .mynav-button {
      text-align: left;
      padding: 0 0 0 10px;
    }
  
  .navbar-brand {
    height: 40px;
    width: 162.5px;
    margin-right: 5px;
  }
   .el-menu {
      border-right: 0;
    }
}
</style>