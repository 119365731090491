<template>
  <div id="app">
    <my-header></my-header>
    <section style="padding-top: 50px">
      <router-view></router-view>
    </section>
    <Footer />
    <!-- move top -->
    <el-backtop></el-backtop>

    <!-- /move top -->
  </div>
</template>

<script>
import MyHeader from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    MyHeader,
    Footer,
  },
  data () {
    return {
      isMobile: false
    };
  },
  watch: {
    // 控制滚动条位置
    $route: function (to, from) {
      // console.log(to, from);
      // document.body.scrollTop = to.meta.scrollTop
      document.body.scrollTop = 0;
      // document.documentElement.scrollTop = to.meta.scrollTop
      document.documentElement.scrollTop = 0;
    },
  },
  mounted () {
    window.addEventListener("resize", this.screenAdapter);
  },
  provide () {
    // vue组件全局传参用一用
    return {
      screenMobile: this.getScreenMobile
    };
  },
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    screenAdapter () {
      const windowWidth = document.documentElement.clientWidth; //实时屏幕宽度
      // 判断屏幕属于大还是小
      if (windowWidth < 768) this.isMobile = true;
    },
    getScreenMobile () {
      return this.isMobile
    }
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
}
</style>
